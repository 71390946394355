<template>
  <el-dialog
    :visible="showCancelDialog"
    @close="close"
    @open="create"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="35%"
    title="Cancelar alquiler"
  >
    <form autocomplete="off" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.cancellation_reason }"
          >
            <label>Motivo de cancelación</label>
            <el-input
              v-model="form.cancellation_reason"
              dusk="cancellation_reason"
            >
              
            </el-input>
            <small
              class="error--text"
              v-if="errors.cancellation_reason"
              v-text="errors.cancellation_reason[0]"
            ></small>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <el-button type="info" @click.prevent="close()">Cerrar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :loading="loading"
            :disabled="loading || !this.form.cancellation_reason"
          >
            <template v-if="loading">
              Finalizando...
            </template>
            <template v-else>
              Finalizar
            </template>
          </el-button>
        </v-col>
      </v-row>
    </form>
  </el-dialog>
</template>

<script>
export default {
  props: ["showCancelDialog", "recordID", "salonID"],
  data() {
    return {
      loading: false,
      titleDialog: null,
      resource: "rentals",
      cleaning_personnel: [],
      errors: {},
      form: {},
    };
  },
  methods: {
    initForm() {
      this.errors = {};
      this.form = {
        id: this.recordID,
        cancellation_reason: null,
      };
    },
    async create() {
      await this.initForm();
    },

    submit() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}/cancel-definitive`, this.form)
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            this.$eventHub.$emit("reloadDataRoomsInUse", 'rooms-in-use');
            this.close();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.message;
          } else {
            console.log(error);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:showCancelDialog", false);
      this.initForm();
    },
  },
};
</script>
