<template>
  <el-dialog
    :visible="showCheckoutDialog"
    @close="close"
    @open="create"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="35%"
    title="CheckOut"
  >
    <form autocomplete="off" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.cleaning_personnel_id }"
          >
            <label>Encargado de limpieza</label>
            <el-select
              v-model="form.cleaning_personnel_id"
              dusk="cleaning_personnel_id"
            >
              <el-option
                v-for="option in cleaning_personnel"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.cleaning_personnel_id"
              v-text="errors.cleaning_personnel_id[0]"
            ></small>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <el-button type="info" @click.prevent="close()">Cancelar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :loading="loading"
            :disabled="loading || !this.form.cleaning_personnel_id"
          >
            <template v-if="loading">
              Finalizando...
            </template>
            <template v-else>
              Finalizar
            </template>
          </el-button>
        </v-col>
      </v-row>
    </form>
  </el-dialog>
</template>

<script>
export default {
  props: ["showCheckoutDialog", "recordID"],
  data() {
    return {
      loading: false,
      titleDialog: null,
      resource: "rentals",
      cleaning_personnel: [],
      errors: {},
      form: {},
    };
  },
  methods: {
    initForm() {
      this.errors = {};
      this.form = {
        id: this.recordID,
        cleaning_personnel_id: null,
      };
    },
    async create() {
      await this.initForm();
      // await this.getTables();
    },
    getTables() {
      this.$http
        .get(`/${this.resource}/cleaning-personnel`)
        .then((response) => {
          this.cleaning_personnel = response.data;
        });
    },
    submit() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}/rent/checkout`, this.form)
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            this.$eventHub.$emit("reloadData", 'rooms-in-use');
            this.close();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.message;
          } else {
            console.log(error);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:showCheckoutDialog", false);
      this.initForm();
    },
  },
};
</script>
