<template>
  <div>
    <el-dialog
      title="Editar alquiler"
      :visible="showDialog"
      @close="close"
      @open="create"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="70%"
    >
      <form autocomplete="off" @submit.prevent="submit" v-if="loaded">
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12">
            <div
              class="form-group"
              :class="{ 'error--text': errors.date_of_exit }"
            >
              <label>Fecha de ingreso</label>
              <el-date-picker
                v-model="form.date_of_entry"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
                format="dd/MM/yyyy HH:mm"
                placeholder="Seleccionar"
                disabled
              >
              </el-date-picker>
              <small
                class="error--text"
                v-if="errors.date_of_exit"
                v-text="errors.date_of_exit[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12">
            <div class="form-group">
              <label>Tarifa</label>
              <el-select
                v-model="form.period_id"
                dusk="period_id"
                @change="changePeriod()"
              >
                <el-option
                  v-for="option in periods"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12">
            <div class="form-group">
              <label>Motivo de viaje</label>
              <el-select v-model="form.reason_travel_id">
                <el-option
                  v-for="option in reason_travel"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="form-group">
              <label>Cliente</label>
              <el-select
                v-model="form.customer_id"
                filterable
                remote
                dusk="customer_id"
                placeholder="Cliente"
                :remote-method="searchRemoteCustomers"
                :loading="loading_search"
                loading-text="Cargando..."
              >
                <el-option
                  v-for="option in customers"
                  :key="option.id"
                  :value="option.id"
                  :label="option.description"
                ></el-option>
              </el-select>
              <small
                class="error--text"
                v-if="errors.customer_id"
                v-text="errors.customer_id[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="4">
            <label>Nuevo</label>
            <el-button
              type="primary"
              class="btn-block"
              @click.prevent="showDialogNewPerson = true"
              icon="el-icon-plus"
              ></el-button
            >
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12">
            <div class="form-group">
              <label>Notas</label>
              <el-input v-model="form.observations"></el-input>
              <small
                class="error--text"
                v-if="errors.observations"
                v-text="errors.observations[0]"
              ></small>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row dense>
          <v-col cols="12" xl="12">
            <v-card color="primary" class="mx-auto pr-2 pl-2" outlined>
              <v-row dense>
                <v-col cols="12" xl="3" lg="3" md="3" sm="4">
                  <div class="form-group">
                    <label>Cantidad</label>
                    <el-input
                      v-model="form_item.quantity"
                      class="input-text-right"
                      ref="quantity"
                      autofocus
                    ></el-input>
                  </div>
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="8">
                  <div class="form-group">
                    <label>Producto / Servicio</label>
                    <el-select
                      v-model="form_item.item_id"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="Buscar..."
                      :remote-method="searchItems"
                      @change="changeItem()"
                      :loading="loading_search_items"
                      loading-text="Cargando..."
                    >
                      <el-option
                        v-for="item in items"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3" sm="4">
                  <div class="form-group">
                    <label>Precio Unit.</label>
                    <el-input
                      v-model="form_item.unit_price_value"
                      class="input-text-right"
                      @input="changeUnitPriceValue"
                    ></el-input>
                  </div>
                </v-col>
                <v-col cols="12" xl="1" lg="1" md="1" sm="8">
                  <el-button
                    type="primary"
                    icon="el-icon-circle-plus"
                    class="btn-block mt-5"
                    @click.prevent="clickAddItem"
                  ></el-button>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="table-responsive">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="3%" class="text-right">N°</th>
                      <th width="10%" class="text-right">Cantidad</th>
                      <th width="30%">Producto / Servicio</th>
                      <th width="10%">Habitación</th>
                      <th class="text-right">Precio Unit.</th>
                      <th class="text-right">Total</th>
                      <th class="text-right">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in form.items" :key="index">
                      <td class="text-right align-middle">
                        {{ index + 1 }}
                      </td>
                      <td class="text-right align-middle">
                        <el-input-number
                          size="mini"
                          v-model="row.quantity"
                          controls-position="right"
                          :min="0"
                          :step="1"
                          @change="changeRow(index)"
                          :readonly="form.period_id == '01' ? true : false"
                        ></el-input-number>
                      </td>
                      <td class="align-middle">
                        {{ row.item.name }}
                      </td>
                      <td class="align-middle">
                        <el-select
                          v-model="row.room_id"
                          :disabled="row.id == null ? false : true"
                        >
                          <el-option
                            v-for="option in filtered_rooms"
                            :key="option.id"
                            :value="option.id"
                            :label="option.name"
                          >
                          </el-option>
                        </el-select>
                      </td>
                      <td class="text-right align-middle">
                        <el-input
                          size="mini"
                          v-model="row.unit_price"
                          @input="changeRow(index, false, true)"
                          class="input-text-right"
                        ></el-input>
                      </td>
                      <td class="text-right align-middle">
                        <el-input
                          size="mini"
                          v-model="row.total"
                          class="input-text-right"
                          readonly
                        ></el-input>
                      </td>
                      <td class="text-right align-middle">
                        <el-button
                          size="mini"
                          type="danger"
                          icon="el-icon-delete"
                          v-if="!row.id"
                          @click.prevent="clickRemoveItem(index)"
                        ></el-button>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <v-row dense class="justify-content-end" v-if="form.total > 0">
              <v-col cols="12" xl="6" lg="12" offset-xl="6">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-if="form.total_discount_global > 0">
                        <td colspan="2" class="text-right">
                          Descuento Global (-):
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_discount_global }}
                        </td>
                      </tr>
                      <tr v-if="form.total_discount_item > 0">
                        <td colspan="2" class="text-right">
                          Descuento por Item (-):
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_discount_item }}
                        </td>
                      </tr>
                      <tr v-if="form.total_discount > 0">
                        <td colspan="2" class="text-right">
                          Descuento Total (-):
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_discount }}
                        </td>
                      </tr>
                      <tr v-if="form.total_exportation > 0">
                        <td colspan="2" class="text-right">
                          Op. Exportación:
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_exportation }}
                        </td>
                      </tr>
                      <tr v-if="form.total_free > 0">
                        <td colspan="2" class="text-right">
                          Op. Gratuitas:
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }} {{ form.total_free }}
                        </td>
                      </tr>
                      <tr v-if="form.total_unaffected > 0">
                        <td colspan="2" class="text-right">
                          Op. Inafectas:
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_unaffected }}
                        </td>
                      </tr> 
                      <tr v-if="form.total_exonerated > 0">
                        <td colspan="2" class="text-right">
                          Op. Exoneradas:
                        </td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_exonerated }}
                        </td>
                      </tr>
                      <tr v-if="form.total_taxed > 0">
                        <td colspan="2" class="text-right">Op. Gravada:</td>
                        <td class="text-right">
                          {{ currency_type.symbol }}
                          {{ form.total_taxed }}
                        </td>
                      </tr>
                      <tr v-if="form.total_igv > 0">
                        <td colspan="2" class="text-right">IGV:</td>
                        <td class="text-right">
                          {{ currency_type.symbol }} {{ form.total_igv }}
                        </td>
                      </tr>
                      <tr class="h6" v-if="form.total > 0">
                        <td colspan="2" class="text-right">Total:</td>
                        <td class="text-right">
                          <strong
                            >{{ currency_type.symbol }} {{ form.total }}</strong
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="form-actions text-right mt-3 mb-3">
          <el-button type="danger" @click.prevent="close()">Cancelar</el-button>
          <el-button type="primary" native-type="submit" :loading="loading">
            <template v-if="loading">
              Actualizando...
            </template>
            <template v-else>
              Actualizar
            </template>
          </el-button>
        </div>
      </form>
    </el-dialog>
    <person-form
      :showDialog.sync="showDialogNewPerson"
      type="customers"
      :external="true"
    >
    </person-form>
  </div>
</template>

<script>

import { calculateRowItem } from "Helpers/functions";
import PersonForm from "../People/Form";
import { deletable } from "Mixins/deletable";
import filter from "lodash/filter";
import round from "lodash/round";
import find from "lodash/find";
import dayjs from "dayjs";

export default {
  props: ["showDialog", "recordID"],
  components: {PersonForm},
  mixins: [deletable],
  data() {
    return {
      loading: false,
      titleDialog: null,
      resource: "rentals",
      items: [],
      all_items: [],
      periods: [],
      rooms: [],
      filtered_rooms: [],
      rates: [],
      allRates: [],
      reason_travel: [],
      currency_types: [],
      currency_type: {},
      errors: {},
      form: {},
      form_item: {},
      loading_search: false,
      showDialogNewPerson: false,
      loaded: false,
      customers: [],
      affectation_igv_types: [],
      loading_search_items: false,
      pickerOptions: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return dayjs().format("YYYY-MM-DD") > time;
        },
      },
    };
  },
  created() {
    this.$eventHub.$on("reloadDataPeople", (customer_id) => {
      this.reloadDataCustomers(customer_id);
    });
  },
  methods: {
    initForm() {
      this.errors = {};
      this.form = {
        id: null,
        prefix: "TKT",
        establishment_id: null,
        warehouse_id: null,
        establishment: {},
        date_of_entry: dayjs().format("YYYY-MM-DD HH:mm:ss"),

        date_of_due: dayjs().format("YYYY-MM-DD"),
        paid: true,
        period_id: "02",
        customer_id: null,
        reason_travel_id: 8,
        currency_type_id: "PEN",
        payment_condition_type_id: "1",
        purchase_order: null,
        exchange_rate_sale: 0,
        total_prepayment: 0,
        total_charge: 0,
        total_discount: 0,
        total_exportation: 0,
        total_free: 0,
        total_taxed: 0,
        total_unaffected: 0,
        total_exonerated: 0,
        total_igv: 0,
        total_base_isc: 0,
        total_isc: 0,
        total_base_other_taxes: 0,
        total_other_taxes: 0,
        total_taxes: 0,
        total_value: 0,
        total: 0,
        operation_type_id: "0101",
        items: [],
        discount: {
          discount_type_id: "02",
          description: "Descuento Global afecta a la base imponible",
          factor: 0,
          amount: 0,
          base: 0,
        },
      };
      this.initFormItem();
    },
    initFormItem() {
      this.form_item = {
        id: null,
        item_id: null,
        item: {},
        room_id: null,
        is_active: true,
        is_rent: false,
        date_of_exit: dayjs()
          .add(1, "day")
          .format("YYYY-MM-DD"),
        time_of_exit: "12:00:00",
        number_of_people: 1,
        number_of_days: 1,
        affectation_igv_type_id: null,
        affectation_igv_type: {},
        has_isc: false,
        system_isc_type_id: null,
        percentage_isc: 0,
        suggested_price: 0,
        quantity: 1,
        aux_quantity: 1,
        unit_price_value: 0,
        unit_price: 0,
        charges: [],
        discount: {
          discount_type_id: "02",
          discount_type: { base: true },
          description: "Descuento que afecta la base imponible del IGV",
          percentage: 0,
          factor: 0,
          amount: 0,
          base: 0,
          is_amount: true,
        },
        attributes: [],
        has_igv: null,
        paid: true,
        note: null,
        item_unit_type_id: null,
      };
    },
    async create() {
      await this.initForm();
      await this.getRecord();
      await this.getStaticTables();
      await this.getTables();
      await this.getAllRooms();
    },
    getRecord() {
      this.$http
        .get(`/${this.resource}/record/${this.recordID}`)
        .then((response) => {
          this.form = response.data.data.rental;
          this.form.warehouse_id = null;
          this.form.date_of_entry = dayjs(this.form.date_of_entry).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          this.reloadDataCustomers(this.form.customer_id)
          // this.form.date_of_exit = dayjs(this.form.date_of_exit).format(
          //   "YYYY-MM-DD"
          // );
        });
    },
    async getStaticTables() {
      await this.$http
        .get(`/${this.resource}/static-tables`)
        .then((response) => {
          this.reason_travel = response.data.reason_travel;
          this.currency_types = response.data.currency_types;
          this.affectation_igv_types = response.data.affectation_igv_types;
          this.changeCurrencyType();
        });
    },
    async getTables() {
      await this.$http
        .get(`/${this.resource}/dynamic-tables`)
        .then((response) => {
          this.periods = response.data.periods;
          this.warehouses = response.data.warehouses;
          this.allRates = response.data.rates;

          this.form.warehouse_id =
            this.warehouses.length > 0 ? this.warehouses[0].id : null;

          this.loaded = true;
        });
    },
    searchRemoteCustomers(input) {
      if (input.length > 3) {
        this.loading_search = true;
        let parameters = `input=${input}`;
        this.$http
          .get(`/${this.resource}/search/customers?${parameters}`)
          .then((response) => {
            this.customers = response.data;
            this.loading_search = false;
          });
      } else {
        this.customers = [];
      }
    },
    reloadDataCustomers(customer_id) {
      this.$http
        .get(`/${this.resource}/search/customer/${customer_id}`)
        .then((response) => {
          this.customers = response.data;
          this.form.customer_id = customer_id;
        });
    },
    async getAllRooms() {
      this.filtered_rooms = []
      await this.$http.get(`/${this.resource}/rooms`).then((response) => {
        this.rooms = response.data;

        this.form.items.forEach((row) => {
          if (row.is_rent) {
            let room = find(this.rooms, { id: row.room_id });
            this.filtered_rooms.push(room);
          }
        });
      });
    },
    changeCurrencyType() {
      this.currency_type = find(this.currency_types, {
        id: this.form.currency_type_id,
      });
    },
    async changeDateOfExit() {
      let date = dayjs(this.form.date_of_exit).format("YYYY-MM-DD");
      let date_cvrt = dayjs(date);

      this.form.number_of_days =
        date_cvrt.diff(this.form.date_of_entry, "day") + 1;

      this.changeRate();
    },
    changePeriod() {
      this.form.items.forEach((row, index) => {
          if (row.is_rent) {
            let room = this.rooms.find((element) => element.id == row.room_id);
  
            if (room) {
              
              this.rates = filter(this.allRates, {
                room_id: room.id,
                period_id: this.form.period_id,
              });
  
              if (this.rates.length == 0 || this.rates == undefined) {
                this.$message.error(
                  `Error! Agrega tarifas para esa habitación ${room.name}`
                );
                return;
              } else {
                row.quantity = 1;
                row.item.aux_quantity = 1;

                if (this.form.period_id == "01") {
  
                  row.date_of_exit = dayjs().format("YYYY-MM-DD");
                  row.time_of_exit = dayjs().add(3, "hour").format("HH:mm:ss");
  
                  
                } else if (this.form.period_id == "03") {
  
                  row.date_of_exit = dayjs().add(7 * row.quantity, "day").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
  
                } else if (this.form.period_id == "04") {
                  row.date_of_exit = dayjs().add(row.quantity, "month").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
  
                } else {
                  row.date_of_exit = dayjs().add(row.number_of_days, "day").format("YYYY-MM-DD");
                  row.time_of_exit = "12:00:00";
                }

                let date = dayjs(row.date_of_exit).format("YYYY-MM-DD");
                let date_cvrt = dayjs(date);
  
                row.number_of_days = date_cvrt.diff(this.form.date_of_entry, "day") + 1

                row.item.unit_price = this.rates[0].price; 
                row.unit_price = this.rates[0].price; 
                // console.log(row.unit_price);
  
                this.row = calculateRowItem(
                  row,
                  this.form.currency_type_id,
                  this.form.exchange_rate_sale
                );

                console.log(this.row.unit_price);
                this.form.items[index] = this.row;
              }
            }
          }
      });

      this.calculateTotal();
      // this.changeRate();
    },
    changeRate() {
      this.form.items
        .filter((el) => el.item_id == 1)
        .forEach((row, index) => {
          let room = this.rooms.find((element) => element.id == row.room_id);

          if (room) {
            this.rates = filter(this.allRates, {
              room_id: room.id,
              period_id: this.form.period_id,
            });

            if (this.rates.length == 0 || this.rates == undefined) {
              this.$message.error(
                `Error! Agrega tarifas para esa habitación ${room.name}`
              );
              return;
            } else {
              row.quantity = this.form.number_of_days;
              row.item.aux_quantity = this.form.number_of_days;

              row.unit_price = this.rates[0].price;

              this.row = calculateRowItem(
                row,
                this.form.currency_type_id,
                this.form.exchange_rate_sale
              );

              this.form.items[index] = this.row;
            }
          }
        });

      this.calculateTotal();
    },
    searchItems(input) {
      if (input.length > 2) {
        this.loading_search_items = true;
        let parameters = `input=${input}`;
        this.$http
          .get(`/${this.resource}/search/items-only?${parameters}`)
          .then((response) => {
            this.items = response.data;
            this.loading_search_items = false;
          });
      } else {
        this.items = [];
      }
    },
    clickAddItem() {
      if (!this.form_item.item_id) {
        return this.$message.error(
          "Error! selecciona un producto para continuar."
        );
      }

      if (!Number(this.form_item.quantity)) {
        return this.$message.error(
          "Error! La cantidad ingresada es incorrrecto."
        );
      }

      if (
        !Number(this.form_item.unit_price) ||
        this.form_item.unit_price <= 0
      ) {
        return this.$message.error(
          "Error! el precio unitario ingresado es incorrrecto."
        );
      }

      this.form_item.room_id = this.filtered_rooms.length > 0 ? this.warehouses[0].id : null;

      this.row = calculateRowItem(
        this.form_item,
        this.form.currency_type_id,
        this.form.exchange_rate_sale
      );

      this.form.items.push(JSON.parse(JSON.stringify(this.row)));

      this.initFormItem();
      this.$refs.quantity.$el.getElementsByTagName("input")[0].focus();

      this.calculateTotal();
    },
    clickRemoveItem(index) {
      if (this.form.items[index].id != null) {
        this.remove( 
          `/${this.resource}/order_item/${this.form.items[index].id}`
        ).then(() => {
          this.form.items.splice(index, 1);
          this.calculateTotal();
        });
      } else {
        this.form.items.splice(index, 1);
        this.calculateTotal();
      }

      // this.form.items.splice(index, 1);
      // this.calculateTotal();
    },
    changeItem() {
      this.form_item.item = find(this.items, { id: this.form_item.item_id });
      this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
      this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
        id: this.form_item.affectation_igv_type_id,
      });

      this.form_item.unit_price_value = this.form_item.item.sale_unit_price;

      let unit_price = this.form_item.item.has_igv
        ? this.form_item.unit_price_value
        : this.form_item.unit_price_value * 1.10;

      this.form_item.unit_price = unit_price;
      this.form_item.static_price = unit_price;
      this.form_item.item.unit_price = unit_price;
    },
    changeUnitPriceValue() {
      let unit_price = this.form_item.item.has_igv
        ? this.form_item.unit_price_value
        : this.form_item.unit_price_value * 1.10;

      this.form_item.unit_price = unit_price;
      this.form_item.static_price = unit_price;
      this.form_item.item.unit_price = unit_price;
    },
    changeRow(index, change_igv_type = false, input = false) {

      let row = this.form.items[index]

      if (row.is_rent) {
            
        if (this.form.period_id == "03") {

          row.date_of_exit = dayjs(this.form.date_of_entry).add(row.quantity * 7, "day").format("YYYY-MM-DD");
          row.time_of_exit = "12:00:00";

        } else if (this.form.period_id == "04") {
          row.date_of_exit = dayjs(this.form.date_of_entry).add(row.quantity, "month").format("YYYY-MM-DD");
          row.time_of_exit = "12:00:00";

        } else {
          row.date_of_exit = dayjs(this.form.date_of_entry).add(row.quantity, "day").format("YYYY-MM-DD");
          row.time_of_exit = "12:00:00";

        }

        let date = dayjs(row.date_of_exit).format("YYYY-MM-DD");
        let date_cvrt = dayjs(date);

        row.number_of_days = date_cvrt.diff(this.form.date_of_entry, "day") + 1;

        console.log(row.date_of_exit);
      }

      row.affectation_igv_type = find(
        this.affectation_igv_types,
        {
          id: row.affectation_igv_type_id,
        }
      );

      if (change_igv_type) {
        row.unit_price_value = row.static_price;
      } else {
        row.unit_price_value = row.unit_price;
      }

      let unit_price = row.item.has_igv
        ? row.unit_price_value
        : row.unit_price_value * 1.10;

      row.item.unit_price = unit_price;

      this.calculateRowItem(index, change_igv_type, input);
    },
    calculateRowItem(index, change_igv_type, input) {
      let currency_type_id_new = this.form.currency_type_id;
      let exchange_rate_sale = this.form.exchange_rate_sale;
      let row = this.form.items[index];
      let currency_type_id_old = row.item.currency_type_id;
      let unit_price = parseFloat(row.item.unit_price);

      if (
        currency_type_id_old === "PEN" &&
        currency_type_id_old !== currency_type_id_new
      ) {
        unit_price = unit_price / exchange_rate_sale;
      }

      if (
        currency_type_id_new === "PEN" &&
        currency_type_id_old !== currency_type_id_new
      ) {
        unit_price = unit_price * exchange_rate_sale;
      }

      let percentage_igv = 10;
      row.static_price = input ? row.unit_price : row.static_price;
      row.unit_price = change_igv_type ? row.static_price : row.unit_price;
      let unit_value = row.unit_price;

      if (row.item_id !== null) {
        if (row.affectation_igv_type.free) {
          unit_value = parseFloat(
            row.unit_price / (1 + percentage_igv / 100) / 1.10
          );
        }
      }

      if (row.affectation_igv_type_id === "10") {
        unit_value = row.unit_price / (1 + percentage_igv / 100);
      }

      console.log(unit_price);

      row.unit_value = unit_value;

      let total_value_partial = unit_value * row.quantity;

      /* Discounts */
      let discount_base = 0;
      let discount_no_base = 0;
      let discount = row.discount;
      if (discount.percentage > 0) {
        if (discount.is_amount) {
          discount.base = total_value_partial;
          discount.amount = discount.percentage / 1.10;

          let percentage =
            100 * (parseFloat(discount.amount) / parseFloat(discount.base));
          discount.factor = percentage / 100;
          if (discount.discount_type.base) {
            discount_base += discount.amount;
          } else {
            discount_no_base += discount.amount;
          }
        } else {
          let percentage = parseFloat(discount.percentage);
          discount.factor = percentage / 100;
          discount.base = total_value_partial;
          discount.amount = discount.base * discount.factor;
          if (discount.discount_type.base) {
            discount_base += discount.amount;
          } else {
            discount_no_base += discount.amount;
          }
        }
      }

      /* Charges */
      let charge_base = 0;
      let charge_no_base = 0;

      let total_isc = 0;
      let total_other_taxes = 0;

      let total_discount = discount_base + discount_no_base;
      let total_charge = charge_base + charge_no_base;
      let total_value = total_value_partial - total_discount + total_charge;
      let total_base_igv = total_value_partial - discount_base + total_isc;

      let total_igv = 0;

      if (row.affectation_igv_type_id === "10") {
        total_igv = (total_base_igv * percentage_igv) / 100;
      }
      if (row.affectation_igv_type_id === "20") {
        //Exonerated
        total_igv = 0;
      }
      if (row.affectation_igv_type_id === "30") {
        //Unaffected
        total_igv = 0;
      }

      let total_taxes = total_igv + total_isc + total_other_taxes;
      let total = total_value + total_taxes;

      row.total_charge = total_charge;
      row.total_discount = total_discount;
      row.total_charge = total_charge;
      row.total_value = total_value;
      row.total_base_igv = total_base_igv;
      row.total_igv = total_igv;
      row.total_taxes = total_taxes;
      row.total = round(total, 2);

      if (row.item_id !== null) {
        if (row.affectation_igv_type.free) {
          row.price_type_id = "02";
          row.unit_price = parseFloat(total_value_partial * 1.10);
          row.total_value = parseFloat(
            total_value_partial - total_discount + total_charge
          );
          row.total_igv = parseFloat(row.total_value * 0.10);
          row.total = parseFloat(row.total_value + row.total_igv);
        } else {
          row.price_type_id = "01";
        }
      }

      //impuesto bolsa
      if (row.has_plastic_bag_taxes) {
        row.total_plastic_bag_taxes = round(
          row.quantity * row.item.amount_plastic_bag_taxes,
          1
        );
      }

      this.calculateTotal();
    },
    calculateTotal() {
      let total_discount = 0;
      let total_charge = 0;
      let total_exportation = 0;
      let total_taxed = 0;
      let total_exonerated = 0;
      let total_unaffected = 0;
      let total_free = 0;
      let total_igv = 0;
      let total_value = 0;
      let total = 0;
      this.form.items.forEach((row) => {
        total_discount += parseFloat(row.total_discount);
        total_charge += parseFloat(row.total_charge);

        if (row.affectation_igv_type_id === "10") {
          total_taxed += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "20") {
          total_exonerated += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "30") {
          total_unaffected += parseFloat(row.total_value);
        }
        if (row.affectation_igv_type_id === "40") {
          total_exportation += parseFloat(row.total_value);
        }
        if (["10", "20", "30", "40"].indexOf(row.affectation_igv_type_id) < 0) {
          total_free += parseFloat(row.total_value);
        }
        if (
          ["10", "20", "30", "40"].indexOf(row.affectation_igv_type_id) > -1
        ) {
          total_igv += parseFloat(row.total_igv);
          total += parseFloat(row.total);
        }
        total_value += parseFloat(row.total_value);
      });

      this.form.total_exportation = round(total_exportation, 2);
      this.form.total_discount = round(total_discount, 2);
      this.form.total_charge = round(total_charge, 2);
      this.form.total_taxed = round(total_taxed, 2);
      this.form.total_exonerated = round(total_exonerated, 2);
      this.form.total_unaffected = round(total_unaffected, 2);
      this.form.total_free = round(total_free, 2);
      this.form.total_igv = round(total_igv, 2);
      this.form.total_value = round(total_value, 2);
      this.form.total_taxes = round(total_igv, 2);
      this.form.total = round(total, 2);
    },
    submit() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}`, this.form)
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            this.$eventHub.$emit("reloadDataRoomsInUse", 'rooms-in-use');
            if (this.form.id) {
              this.close();
            }
            this.initForm();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:showDialog", false);
      this.initForm();
    },
  },
};
</script>
