<template>
  <div>
    <!-- <div class="d-flex justify-space-between align-center mb-2 mt-2">
      <div class="input-wrap">
        <h5 class="mb-2">Hab. Ocupadas</h5>
      </div>
      <div class="action-btn-wrap">
        <v-btn color="accent" @click="downloadPDF()">
          Descargar
        </v-btn>
      </div>
    </div> -->

    <v-row dense class="ma-2">

      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
        <div class="emb-card pa-4 primary text-center">
          <h4 class="mb-2 font-weight-bold ">
            {{ totals.occupied_rooms }}
          </h4>
          <p class="mb-0">HAB. OCUPADAS</p>
        </div>
			</v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
        <div class="emb-card pa-4 primary text-center">
            <h4 class="mb-2 font-weight-bold ">
              {{ totals.available_rooms }}
            </h4>
            <p class="mb-0">HAB. DISPONIBLES</p>
        </div>
			</v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
        <div class="emb-card pa-4 primary text-center">
            <h4 class="mb-2 font-weight-bold ">
              {{ totals.number_occupants }}
            </h4>
            <p class="mb-0">N° OCUPANTES</p>
        </div>
			</v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
					<div class="emb-card pa-4 primary text-center">
              <h4 class="mb-2 font-weight-bold ">
                {{ totals.rooms_vacated_today }}
              </h4>
              <p class="mb-0">HAB. X DESOCUPAR HOY</p>
					</div>
			</v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="2">
					<div class="emb-card pa-4 primary text-center">
              <h6 class="mb-2 font-weight-bold ">
                Reporte
              </h6>
              <p class="mb-0">
                <v-btn color="accent" @click="downloadPDF()" outlined>
                  Descargar
                </v-btn>
              </p>
					</div>
			</v-col>
    </v-row>

    <v-card color="secondary" class="pa-3">
      <div class="row">
        <div class="col-xl-12">
          <div class="table-responsive">
            <v-simple-table dense >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="1%">N°</th>
                    <th class="text-center">USUARIO</th>
                    <th>CLIENTE</th>
                    <th>N° CLIENTE</th>
                    <th class="text-center">FECHA/HORA INGRESO</th>
                    <th class="text-center">HAB.</th>
                    <th class="text-center">TIPO.</th>
                    <th class="text-center">FECHA/HORA SALIDA</th>
                    <th class="text-center">D</th>
                    <th class="text-center">P</th>
                    <th class="text-right">PRECIO</th>
                    
                    <th>OBSERVACIÓN</th>
                    <th class="text-right">TOTAL</th>
                    <th class="text-right">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in records"
                    :key="index"
                    :class="row.items.find(it => it.alter) ? 'warning--text' : ''"
                  >
                    <td class="align-middle">{{ Number(index) + 1 }}</td>
                    <td class="text-center">{{ row.user }}</td>
                    <td>{{ row.customer }}</td>
                    <td>{{ row.customer_number }}</td>
                    <td class="text-center">{{ row.date_of_entry }}</td>
                    <template v-if="row.items.length > 1">
                      <td colspan="6" class="text-center">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item) in row.items"
                                :class="item.alert ? 'warning--text' : ''"
                                :key="item.room"
                              >
                                <td>{{ item.room }}</td>
                                <td>{{ item.room_type }}</td>
                                <td>{{ item.date_of_exit + " " + item.time_of_exit }}</td>
                                <td>{{ (row.period_id == '01') ? 0 : item.number_of_days }}</td>
                                <td>{{ item.number_of_people }}</td>
                                <td class="text-right">{{ item.total }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </template>
                    <template v-else>
                      <td class="text-center">{{ row.items[0].room }}</td>
                      <td class="text-center">{{ row.items[0].room_type }}</td>
                      <td class="text-center">{{ row.items[0].date_of_exit + " " + row.items[0].time_of_exit }}</td>
                      <td class="text-center">{{ (row.period_id == '01') ? 0 : row.items[0].number_of_days }}</td>
                      <td class="text-center">{{ row.items[0].number_of_people }}</td>
                      <td class="text-right">{{ row.items[0].total }}</td>
                    </template>
                      <!-- <template v-for="(row, index) in row.items">
                        <span :key="index">{{ row.room + " " }}</span>
                      </template> -->
                    
                    <td>{{ row.observations }}</td>
                    <td class="text-right">{{ row.total }}</td>
                    <td class="text-right">
                      <v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="ml-1"
														color="success"
														dark
														v-bind="attrs"
														v-on="on"
														fab
														x-small
													>
														<v-icon>mdi-format-list-bulleted</v-icon>
													</v-btn>
												</template>
												<v-card dark color="secondary">
													<v-row class="mr-1 ml-1">
														<v-col cols="12" xl="12" lg="12">
															<v-simple-table dense>
																<template v-slot:default>
																	<thead>
																		<tr>
																			<th class="text-right">
																				Cantidad
																			</th>
																			<th class="text-left">
																				Producto/Serivico
																			</th>
																			<th class="text-right">
																				Total
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr
																			v-for="(item,
																			index) in row.details"
																			:key="index"
																		>
																			<td>
																				{{ item.quantity }}
																			</td>
																			<td>
																				{{ item.item.name }}
																			</td>
																			<td>
																				{{
																					item.total
																				}}
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-col>
													</v-row>
												</v-card>
											</v-menu>
                      <v-btn
                        color="error"
                        fab
                        x-small
                        dark
                        class="ml-1"
                        @click.prevent="
                          clickCancelRental(row.id)
                        "
                        v-if="row.diffInMins < 10"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        color="info"
                        class="ma-1"
                        fab
                        x-small
                        dark
                        @click="editRental(row.id)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="accent"
                        class="mr-1"
                        fab
                        x-small
                        dark
                        @click="clickCheckOut(row.id)"
                      >
                        <v-icon>mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </v-card>

    <rental-edit :showDialog.sync="showDialog" :recordID="recordID">
    </rental-edit>
    <checkout-form
      :showCheckoutDialog.sync="showCheckoutDialog"
      :recordID="recordID"
    >
    </checkout-form>
    <cancel-form
      :showCancelDialog.sync="showCancelDialog"
      :recordID="recordID"
    >
    </cancel-form>
  </div>
</template>

<script>

import CheckoutForm from "../Rentals/Component/Checkout";
import CancelForm from "../Rentals/Component/Cancellation";
import { deletable } from "Mixins/deletable";
import queryString from "query-string";
import RentalEdit from "./Edit.vue";
import dayjs from "dayjs";

export default {
  components: { RentalEdit, CheckoutForm, CancelForm },
  mixins: [deletable],
  data() {
    return {
      resource: "rooms-in-use",
      showDialog: false,
      recordID: null,
      records: [],
      loading: false,
      showCheckoutDialog: false,
      showCancelDialog: false,
      search: {
        customer_id: null,
        d_start: dayjs()
          .startOf("month")
          .format("YYYY-MM-DD"),
        d_end: dayjs().format("YYYY-MM-DD"),
      },
      pickerOptionsDates: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return this.search.d_start > time;
        },
      },
      pickerOptionsDateOfIssue: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return dayjs().format("YYYY-MM-DD") < time;
        },
      },
      intervalid1: "",
      sendAlert: false,
      totals: {
        occupied_rooms: 0,
        available_rooms: 0,
        number_occupants: 0,
        rooms_vacated_today: 0,
      },
      emits: []
    };
  },
  async created() {
    await this.getRecords();
    await this.getData();

    this.$eventHub.$on("reloadDataRoomsInUse", (resource) => {
      if (resource == this.resource) {
        this.getRecords();
      }
    });
    
  },
  mounted: function() {
    this.reloadData();
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
    // this.$eventHub.destroy('reloadData');
  },
  methods: {
    reloadData: function() {
      this.intervalid1 = setInterval(
        function() {
          this.getRecords();

          if (this.sendAlert) {
            let snd = new Audio("/static/audio/alert.mp3");
            snd.play();
            snd.muted = false;
          }
        }.bind(this),
        240000
      );
    },
    getRecords: function() {
      this.loading = true;
      return this.$http
        .get(`/${this.resource}/records?${this.getQueryParameters()}`)
        .then((response) => {
          this.records = response.data.records;
          this.sendAlert = response.data.send_alert;

          // console.log(this.sendAlert);

          this.loading = false;
        });
    },
    getQueryParameters() {
      return queryString.stringify({
        ...this.search,
      });
    },
    getData() {
      this.$http.get(`/${this.resource}/data`)
        .then((response) => {
          this.totals = response.data.totals;
        });
    },
    editRental(recordID) {
      this.recordID = recordID;
      this.showDialog = true;
    },
    clickCancelRental(rentalID) {
      this.recordID = rentalID;
      this.showCancelDialog = true;
    },
    clickCheckOut(rentalID) {
      // this.recordID = rentalID;
      // this.showCheckoutDialog = true;
      this.checkout(`/rentals/rent/checkout/${rentalID}`).then(() => {
        this.$eventHub.$emit("reloadDataRoomsInUse", this.resource);
        this.reloadData();
        // this.getRoomsBySalon(salonID); 
      });
    },
    downloadPDF() {
      this.$http({
          url: `/${this.resource}/pdf`,
          method: "get",
          responseType: "blob",
          params: this.form
      }).then(response => {
          var fileURL = window.URL.createObjectURL(
              new Blob([response.data])
          );
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "ReporteHabsEnUso.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
      });
    }
  },
};
</script>
